import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/SEO";
import HeaderHome from "../components/layout/HeaderHome.js";
import SiteTitleQuery from "../components/SiteTitleQuery";

function NotFoundPage() {
  return (
    <SiteTitleQuery
      render={data => (
        <Layout title={data.site.siteMetadata.title}>
          <SEO
            title="Sidan hittades inte"
            keywords={[]}
          />

<HeaderHome data={data} />
          <div className="flex justify-center">
            <h1 className="bg-yellow-400 text-4xl font-bold inline-block my-20 p-3 ">
              404 Sidan hittades inte.
            </h1>
          </div>
        </Layout>
      )}
    />
  );
}

export default NotFoundPage;
